import { LinkProps } from "../api/Props";


function LinkView(props: LinkProps) {
  const link = props.link;

  function openLink() {
    window.openLink(link.link, link.internal);
  }

  if (link.type === "icon") {
    return (
      <div className={"app-icon " + link.name} onClick={openLink}></div>
    );
  } else {
    return (
      <span className="footer-link" onClick={openLink}>
        {link.name}
      </span>
    );
  }
}

export default LinkView;