import { ProfileProps } from "../api/Props";
import DescriptionView from "./DescriptionView";
import LinkView from "./LinkView";

function ProfileView(props: ProfileProps) {

  const profile = props.profile;
  const rounded = profile.rounded ? "profile-rounded " : "";
  return (
    <div className="team-profile">
      <div className="full-profile">
        <div className={"profile " + rounded + profile.image}></div>
        <div className="profile-name">{profile.name}</div>
        <div className="profile-title">{profile.title}</div>
        <div className="profile-lines">
          <DescriptionView list={profile.lines} />
        </div>
        <div className="profile-icons">
          {
            profile.apps.map((link, index) => {
              return (<LinkView link={link} key={index} />)
            })
          }
        </div>
      </div>
    </div>
  );
}

export default ProfileView;