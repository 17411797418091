import { Content, MenuItem, Product, Footer, Teams, TypeObject, CacheObject } from "./Models";


declare global {
  interface Window {
    openLink: ((path: string, internal: boolean) => void);
    showAlert: ((message: string) => void);
  }
}

const API = {

  cache: {} as TypeObject,

  async fetchData(): Promise<CacheObject> {
    const response = await fetch("/data/data.json");
    if (response.ok) {
      const data = await response.json();
      return new Promise((resolve, reject) => {
        this.cache = data;
        resolve({
          key: "server",
          data: data,
          error: false
        } as CacheObject);
      });
    } else {
      return new Promise((resolve, reject) => {
        reject({
          key: "server",
          data: response.text,
          error: true
        } as CacheObject);
      });
    }
  },

  async getFromCache(key: string): Promise<CacheObject> {
    if (this.cache[key]) {
      return new Promise((resolve, reject) => {
        resolve({
          key: key,
          data: this.cache[key],
          error: false
        } as CacheObject);
      });
    } else {
      const response = await this.fetchData();
      if (response.error) {
        return new Promise((resolve, reject) => {
          reject({
            key: key,
            data: response.data,
            error: true
          } as CacheObject);
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve({
            key: key,
            data: this.cache[key],
            error: false
          } as CacheObject);
        });
      }
    }
  },

  async getMenus(): Promise<Array<MenuItem>> {
    const response = await this.getFromCache("menu");
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Array<MenuItem>);
      });
    }
  },

  async getHomeMain(name: string): Promise<Content> {
    const response = await this.getFromCache(name);
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Content);
      });
    }
  },

  async getProduct(name: string): Promise<Product> {
    const response = await this.getFromCache(name);
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Product);
      });
    }
  },

  async getGallery(name: string): Promise<Teams> {
    const response = await this.getFromCache(name);
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Teams);
      });
    }
  },

  async getFooter(): Promise<Footer> {
    const response = await this.getFromCache("footer");
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Footer);
      });
    }
  }

};

export default API;