import { useEffect, useState } from "react"
import { Content } from "../api/Models";
import API from "../api/API";
import { PageProps } from "../api/Props";
import DescriptionView from "./DescriptionView";

function BannerView(props: PageProps) {
  const [content, setContent] = useState({
    title: "",
    description: [],
    imageCss1: "",
    imageCss2: "",
    showImage: false,
    responsive: 0,
    hasWhite: false
  } as Content);

  useEffect(() => {
    API.getHomeMain(props.name).then((content) => {
      setContent(content);
    });
  }, [props.name]);

  const whiteBackground = content.hasWhite ? "white-background" : "";
  const marginTop = content.hasWhite ? "less-margin-top" : "";
  const titleCSS = content.hasWhite ? "big-title-small " : "";
  return (
    <div className="banner">
      <div className="grid-container">
        <div className={"grid-content-view " + content.imageCss1}>
          <div className={whiteBackground}>
            <div className={"big-title  main-layer " + titleCSS + marginTop}>{content.title}</div>
            <DescriptionView list={content.description} />
          </div>
        </div>
        <div className={"grid-image-view " + content.imageCss2}>
        </div>
      </div>
    </div>
  );
}

export default BannerView;