import { DescriptionProps } from "../api/Props";

function DescriptionView(props: DescriptionProps) {
  return (
    <div className="description">
      <div>
        {
          props.list.map((text, index) => {
            return (<p dangerouslySetInnerHTML={{ __html: text }} key={index}></p>);
          })
        }
      </div>
    </div>
  );
}

export default DescriptionView;