import { useEffect, useState } from "react";
import { PageProps } from "../api/Props";
import { TeamProfile, Teams } from "../api/Models";
import API from "../api/API";
import ProfileView from "./ProfileView";

function TeamsView(props: PageProps) {

  const [teams, setTeams] = useState({ profiles: [] } as Teams);

  useEffect(() => {
    API.getGallery(props.name).then((gallery) => {
      setTeams(gallery);
    });
  }, [props.name]);

  const chunks = (items: Array<TeamProfile>, size: number): Array<Array<TeamProfile>> => {
    return items.length > size ? [items.slice(0, size), ...chunks(items.slice(size), size)] : [items]
  }

  const maxElement = 3;
  const profiles = chunks(teams.profiles, maxElement);
  return (
    <div className="teams">
      <div className="flex-container">
        {
          profiles.map((subProfiles, index) => {
            return (<RowProfiles key={index} profiles={subProfiles} />);
          })
        }
      </div>
      <div className="blank-space"></div>
    </div>
  );
}

function RowProfiles(team: Teams) {
  return (
    <div className="flex-row">
      {
        team.profiles.map((profile, index) => {
          return (
            <div key={index}>
              <ProfileView profile={profile} />
            </div>
          );
        })
      }
    </div>
  );
}

export default TeamsView;