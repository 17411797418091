import MainEntry from '../components/flow/MainEntry';

function Typetail() {
  return (
    <div className="typetail">
      <MainEntry />
    </div>
  );
}

export default Typetail;
