import { ScreenProps } from "../api/Props";
import BannerView from "../elements/BannerView";
import FooterView from "../elements/FooterView";
import GalleryView from "../elements/GalleryView";
import TeamsView from "../elements/TeamsView";

function AboutScreen(props: ScreenProps) {
  return (
    <div className="about-us">
      <div className="container">
        <section className="tail-main-second-half"><BannerView name="tail-main" /></section>
        <section><GalleryView name="tiles" /></section>
        <section><TeamsView name="team" /></section>
        <section><FooterView name="footer" /></section>
      </div>
    </div>
  );
}

export default AboutScreen;