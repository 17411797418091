import { AddressProps } from "../api/Props";
import DescriptionView from "./DescriptionView";

function AddressView(props: AddressProps) {

  const address = props.address;
  const imageClassName = "address-grid-image-view " + ((address.local) ? "local-office" : "us-office");
  return (
    <div className="address-grid-container grid-address-container-content">
      <div className="address-grid-content-view">
        <div className="footer-address-title">
          {address.title}
        </div>
        <DescriptionView list={address.lines} />
      </div>
      <div className={imageClassName}>
      </div>
    </div >
  );
}

export default AddressView;