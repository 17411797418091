import { ScreenProps } from "../api/Props";
import BannerView from "../elements/BannerView";
import FooterView from "../elements/FooterView";
import ProductView from "../elements/ProductView";

function HomeScreen(props: ScreenProps) {
  return (
    <div className="home">
      <div className="container">
        <section><BannerView name="home-main" /></section>
        <section><ProductView name="home-mobius" /></section>
        <section><ProductView name="home-amend" /></section>
        <section><FooterView name="footer" /></section>
      </div>
    </div>
  );
}

export default HomeScreen;