import { useEffect, useState } from "react";
import { PageProps } from "../api/Props";
import { Teams } from "../api/Models";
import API from "../api/API";
import ProfileView from "./ProfileView";


function GalleryView(props: PageProps) {
  const [teams, setTeams] = useState({ profiles: [] } as Teams);

  useEffect(() => {
    API.getGallery(props.name).then((gallery) => {
      setTeams(gallery);
    });
  }, [props.name]);

  const size = teams.profiles.length;
  return (
    <div className="teams">
      <div className={"gallery-container-" + size}>
        {
          teams.profiles.map((profile, index) => {
            return (
              <div className={"gallery-item" + (index + 1)} key={index}>
                <ProfileView profile={profile} />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default GalleryView;