import { useEffect, useState } from "react";
import { Product } from "../api/Models";
import API from "../api/API";
import { PageProps } from "../api/Props";
import DescriptionView from "./DescriptionView";

function ProductView(props: PageProps) {

  const [product, setProduct] = useState({
    title: "",
    description: [],
    box: "right",
    background: "",
    link: "",
    internal: false
  } as Product);

  useEffect(() => {
    API.getProduct(props.name).then((product) => {
      setProduct(product);
    });
  }, [props.name]);

  function openLink() {
    window.openLink(product.link, product.internal);
  }

  const right = (product.box === "right");
  const boxCss = right ? "right-box" : "left-box";
  return (
    <div className="product">
      <div className="grid-container">
        <div className="grid-content-view">
          <div className={"big-title black-font"}><span className={boxCss}>{product.title}</span></div>
          <DescriptionView list={product.description} />
          <div className="product-action">
            <button className={"demo-button button-action " + ((right) ? "right-demo-button" : "left-demo-button")} onClick={openLink}>
              Request for demo
            </button>
          </div>
        </div>
        <div className={"grid-image-view " + product.background}>
        </div>
      </div>
    </div>
  );
}

export default ProductView;