import { useEffect, useState } from "react";
import { MenuItemProps, MenuState, PageProps } from "../api/Props";
import API from "../api/API";
import { MenuItem } from "../api/Models";

function MenuView(props: PageProps) {
  const [menus, setMenus] = useState({ menuItems: [], responsive: false } as MenuState);
  useEffect(() => {
    API.getMenus().then((menuItems) => {
      console.log("API", menus.responsive);
      menuItems.forEach((lItem: MenuItem) => {
        if (lItem.path === window.location.pathname) {
          lItem.active = true;
        } else {
          lItem.active = false;
        }
      });
      setMenus({ menuItems: menuItems, responsive: menus.responsive });
    });
  }, [props.name]);

  const selectMenu = (item: MenuItem) => {
    window.openLink(item.path, true);
  };

  const changeResponsive = () => {
    const menusState = { menuItems: [...menus.menuItems], responsive: !menus.responsive };
    setMenus(menusState);
  };

  return (
    <div className="menubar">
      <div className={(menus.responsive) ? "topnav  responsive" : "topnav"}>
        {
          menus.menuItems.map((menu, index) => {
            return (<MenuItemView menu={menu} key={index} selected={() => selectMenu(menu)} />);
          })
        }
        <span className="icon" onClick={changeResponsive}>Menu</span>
      </div>
    </div>
  );
}

function MenuItemView(props: MenuItemProps) {
  const className = (props.menu.active) ? "active" : "";
  return (
    <span className={className} onClick={(e) => props.selected()}>{props.menu.name}</span>
  );
}

export default MenuView;