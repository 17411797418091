import { useEffect, useState } from "react";
import { ScreenProps, ScreenState } from "../api/Props";
import AboutScreen from "../screen/AboutScreen";
import { navigate, Router } from "@reach/router";
import OverlayView from "../elements/OverlayView";
import HomeScreen from "../screen/HomeScreen";
import MenuView from "../elements/MenuView";

function MainEntry(props: ScreenProps) {
  const pathname = window.location.pathname || "/";
  const win = pathname.includes("terms") || pathname.includes("privacy");
  const previous = window.localStorage.getItem("pathname") || "/";
  if (!win) {
    window.localStorage.setItem("pathname", pathname);
  }
  const initState = { window: win, back: win ? previous : pathname } as ScreenState;
  const [screen, setScreen] = useState(initState);

  const openLink = (path: string, internal: boolean) => {
    if (internal) {
      navigate(path);
      window.location.reload();
    } else {
      window.open(path, "_blank");
    }
  };

  const setOpenLink = () => { window.openLink = openLink; };

  useEffect(setOpenLink, [props]);

  const back = () => {
    const path = screen.back || "/";
    window.openLink(path, true);
  }

  const path = pathname.replace("/", "").toLowerCase();
  const overlay = (screen.window) ? <OverlayView close={() => { back(); }}></OverlayView> : "";
  return (
    <div className="main-entry screen">
      <MenuView name={path} />
      <Router>
        <HomeScreen path="/" />
        <AboutScreen path="/team" />
      </Router>
      {overlay}
    </div>
  );
}

export default MainEntry;