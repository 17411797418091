import { useEffect, useState } from "react";
import { OverlayProps, OverlayState } from "../api/Props";
import DescriptionView from "./DescriptionView";

function OverlayView(props: OverlayProps) {
  const [content, setContent] = useState({ content: "" } as OverlayState);

  useEffect(() => {
    const path = window.location.pathname;
    const url = path.includes("terms") ? "/data/terms.txt" : "/data/privacy.txt";
    fetch(url)
      .then(response => response.text())
      .then(text => setContent({ content: text }));
  }, []);

  return (
    <div className="dialog-window">
      <span className="close" onClick={props.close}></span>
      <div className="overlay-window">
        <DescriptionView list={[content.content]}></DescriptionView>
      </div>
    </div>
  );
}

export default OverlayView;