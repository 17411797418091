import { useEffect, useState } from "react";
import API from "../api/API";
import { Footer } from "../api/Models";
import LinkView from "./LinkView";
import AddressView from "./AddressView";
import { PageProps } from "../api/Props";

function FooterView(props: PageProps) {
  const [footer, setFooter] = useState({
    message: "Say hello.",
    reach: "REACH OUT TO US AT: ",
    email: "founders@typetail.com",
    addresses: [],
    leftLinks: [],
    rightLinks: []
  } as Footer);

  useEffect(() => {
    API.getFooter().then((footer) => {
      setFooter(footer);
    });
  }, [])

  const items = ["address-grid-content-view", "address-grid-image-view"];
  return (
    <div className="footer">
      <div className="footer-layer">
        <div className="footer-content">
          <div className="big-title white-font footer-message">{footer.message}</div>
          <div className="padding-top">
            <span className="uppercase">{footer.reach}</span>
            <span className="footer-email" onClick={() => { window.open("mailto:" + footer.email) }}>{footer.email}</span>
          </div>
          <div className="address-grid-container">
            {
              footer.addresses.map((address, index) => {
                return (
                  <div className={items[index]} key={index}>
                    <AddressView address={address} />
                  </div>
                );
              })
            }
          </div>
          <div className="footer-spliter"></div>
          <div className="footer-status">
            <div className="app-icons">
              {
                footer.leftLinks.map((link, index) => {
                  return (<LinkView link={link} key={index} />);
                })
              }
            </div>
            <div>
              {
                footer.rightLinks.map((link, index) => {
                  return (<LinkView link={link} key={index} />);
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterView;